import {
  Link as RouterLink,
  useParams
} from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import { passwordRecoverRequest } from './WebApi'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  root: {
    width: '800px',
    margin: '2em auto'
  },
  grid: {
    alignItems: 'center'
  }
}))

const ForgotPassword = observer(() => {
  const classes = useStyles()
  const [fieldValues, setFieldValues] = React.useState({
    email: ''
  })

  const [resultMessage, setResultMessage] = React.useState('')

  const {enqueueSnackbar} = useSnackbar()

  const handleChange = (fieldName, checkbox) => event => {
    setFieldValues({
        ...fieldValues,
        [fieldName]: checkbox === true ? event.target.checked : event.target.value,
    })
  }

  const [validationErrors, setValidationErrors] = React.useState({})
  const [requesting, setRequesting] = React.useState(false)

  const validate = () => {
    const errors = {}
    const requiredFields = ['email']

    requiredFields.forEach(f => {
      if (fieldValues[f] === "" || fieldValues[f] === null) {
        errors[f] = ""
      }
    })

    setValidationErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleSendClick = event => {
    if (!validate()) {
      return
    }

    setRequesting(true)
    passwordRecoverRequest(fieldValues.email)
      .finally(() => setRequesting(false))
      .then(resp => resp.json())
      .then(data => {
        if (!data.success) {
          enqueueSnackbar(data.message, {variant: 'error'})
        } else {
          setResultMessage(data.message)
        }
      })
  }

  if (resultMessage) {
    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          {resultMessage}
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.root}>

      <Typography variant="h4" gutterBottom>
        Восстановление доступа
      </Typography>

      <Typography variant="body1" paragraph>
        Ссылка на страницу восстановления пароля действительна 24 часа.
      </Typography>

      <form noValidate>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={6}>
            <TextField
                fullWidth
                required
                label="E-mail"
                margin="normal"
                variant="outlined"
                value={fieldValues.email}
                error={validationErrors.email !== undefined}
                helperText={validationErrors.email !== undefined ? validationErrors.email : ""}
                onChange={handleChange('email')}
            />
          </Grid>

          <Grid item xs={6}>
            <Button variant="contained" color="primary" type='submit' disabled={requesting} onClick={handleSendClick}>Отправить</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
})

export default ForgotPassword
