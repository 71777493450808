import * as WebApi from '../WebApi'

import { action, decorate, observable } from 'mobx'

class LiccertStore {
  liccerts = []
  showNew = false

  loadLiccerts() {
    WebApi.loadLiccerts().then((data) => {
      console.log(data)
    })
  }
}

decorate(LiccertStore, {
    liccerts: observable,
    showNew: observable,
    loadLiccerts: action
})

export default new LiccertStore()
