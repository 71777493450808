import 'moment/locale/ru'

import * as constants from '../constants'
import * as moment from 'moment'

import { Link, Route, Switch } from "react-router-dom"

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/GetApp'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import NewLiccert from './NewLiccert'
import Paper from '@material-ui/core/Paper'
import PaymentIcon from '@material-ui/icons/Payment'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import { loadLiccerts } from '../WebApi'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import { useStores } from '../hooks'

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  toolButton: {
    padding: 0,
  }
})

moment.locale('ru')

const LiccertTable = observer(() => {
  const classes = useStyles()
  const [data, setData] = React.useState([])
  const [hoveredRow, setHoveredRow] = React.useState(null)

  const {liccertStore} = useStores()

  React.useEffect(() => {
    loadLiccerts().then(resp => {
      setData(resp)
    })
  }, [])

  const downloadCert = id => {
    const url = '/ca/liccert/download/' + id
    // const url = 'http://localhost:8081/ca/liccert/download/' + id
    window.open(url)
  }

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Button variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />} component={Link} to="/liccerts/new">
          Создать
        </Button>
      </Toolbar>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Создан</TableCell>
            <TableCell align="left">Кол-во лицензий</TableCell>
            <TableCell align="left">Статус</TableCell>
            <TableCell align="left">Стоимость</TableCell>
            <TableCell align="left">Начало</TableCell>
            <TableCell align="left">Окончание</TableCell>
            <TableCell align="left">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 &&
            <TableRow>
              <TableCell align="center" colSpan={7}>
                У Вас нет лицензионных сертификатов.
              </TableCell>
            </TableRow>
          }

          {data.length > 0 && data.map((row) => (
            //<TableRow hover key={row.n} onMouseEnter={() => setHoveredRow(row.n)} onMouseLeave={() => setHoveredRow(null)}>
            <TableRow hover key={row.id}>
              <TableCell align="left">{moment(row.createdAt).format('L')}</TableCell>
              <TableCell align="left">{row.licenseCount}</TableCell>
              {/*<TableCell align="left">{moment(row.modifiedAt).format('L')}</TableCell>*/}
              <TableCell align="left">{row.stateName}</TableCell>
              <TableCell align="left">{row.finalCost} {row.currencyCode}</TableCell>
              <TableCell align="left">{moment(row.beginDate).format('L')}</TableCell>
              <TableCell align="left">{moment(row.endDate).format('L')}</TableCell>
              <TableCell align="left">
                {row.stateId === constants.FREE_ACTIVE_CERT_STATE_ID &&
                  <IconButton className={classes.toolButton} aria-label="download" title="Скачать" onClick={() => downloadCert(row.id)}>
                    <DownloadIcon />
                  </IconButton>
                }

                {/*//hoveredRow === row.n &&
                  <IconButton className={classes.toolButton} aria-label="download" title="Скачать">
                    <DownloadIcon />
                  </IconButton>
                */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
})

export default function Liccerts() {
  return (
    <Switch>
      <Route exact path="/liccerts">
        <LiccertTable />
      </Route>
      <Route path="/liccerts/new">
        <NewLiccert />
      </Route>
    </Switch>
  )
}
