import * as WebApi from '../WebApi'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import objectHash from 'object-hash'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useStores } from '../hooks'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(10, 10),
    width: '700px',
    margin: '0 auto'
  },
}))

const RegDataForm = observer(() => {
  const classes = useStyles()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {miscStore, userStore} = useStores()

  const [fieldValues, setFieldValues] = React.useState({
    email: '',
    fullName: '',
    password: '',
    repeatPassword: '',
    countryId: '',
    timeZoneId: '',
    fullAddress: '',
  })

  const [formHash, setFormHash] = React.useState('')

  React.useEffect(() => {
    WebApi.loadAccount().then(data => {
      const values = {...fieldValues, ...data}
      setFormHash(objectHash(values))
      setFieldValues(values)
    })
  }, [])

  let [formChanged, setFormChanged] = React.useState(false)

  const handleChange = (fieldName, checkbox, converter=(v) => v) => event => {
    const values = {
      ...fieldValues,
      [fieldName]: checkbox === true ? event.target.checked : converter(event.target.value),
    }
    setFieldValues(values)
    setFormChanged(formHash !== objectHash(values))
  }

  const [validationErrors, setValidationErrors] = React.useState({})

  const validate = () => {
    const errors = {}
    const requiredFields = ['email', 'fullName', 'countryId', 'timeZoneId', 'fullAddress']

    requiredFields.forEach(f => {
      if (fieldValues[f] === '' || fieldValues[f] === null) {
        errors[f] = ''
      }
    })

    if (fieldValues.repeatPassword !== fieldValues.password) {
      errors['password'] = true
      errors['repeatPassword'] = 'Пароли должны совпадать'
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      return false
    }

    setValidationErrors({})
    return true
  }

  const handleSaveClick = event => {
    if (!validate()) {
      return
    }

    const {repeatPassword, ...dataToSend} = fieldValues

    if (dataToSend.password === '') {
      dataToSend.password = null
    }

    WebApi.editAccount(dataToSend)
      .then(resp => resp.json())
      .then(resp => {
        if (resp.validationErrors) {
          setValidationErrors(resp.validationErrors)
        }

        if (resp.message) {
          enqueueSnackbar(resp.message, {variant: resp.success ? 'success' : 'error'})
        }

        if (resp.success) {
          setFormHash(objectHash(fieldValues))
          setFormChanged(false)
        }
      });
  }

  const timeZoneTitle = () => {
    var tz = miscStore.timeZones.find(v => v.id === fieldValues.timeZoneId)
    return tz === undefined ? '' : tz.name
  }

  return (
    <Paper className={classes.root}>
      <Typography variant='h5' gutterBottom>
        Данные пользователя
      </Typography>
      <Typography variant='body1' paragraph>
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
              fullWidth
              required
              label='E-mail'
              margin='normal'
              value={fieldValues.email}
              error={validationErrors.email !== undefined}
              helperText={validationErrors.email !== undefined ? validationErrors.email : ''}
              onChange={handleChange('email')}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
              fullWidth
              required
              label='Имя / Название'
              margin='normal'
              value={fieldValues.fullName}
              error={validationErrors.fullName !== undefined}
              helperText={validationErrors.email !== undefined ? validationErrors.email : ''}
              onChange={handleChange('fullName')}
            />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth error={validationErrors.countryId !== undefined}>
            <InputLabel required htmlFor='ml-regform-field-country'>Страна</InputLabel>
            <Select
              native
              value={fieldValues.countryId}
              onChange={handleChange('countryId', false, parseInt)}
              inputProps={{
                name: 'country',
                id: 'ml-regform-field-country',
              }}
            >
              {miscStore.countries.map((v) => <option key={v.id} value={v.id}>{v.name}</option>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth error={validationErrors.timeZoneId !== undefined}>
            <InputLabel required htmlFor='ml-regform-field-timezone'>Временная зона</InputLabel>
            <Select
              native
              value={fieldValues.timeZoneId}
              onChange={e => handleChange('timeZoneId', false, parseInt)(e)}
              title={timeZoneTitle()}
              inputProps={{
                name: 'timezone',
                id: 'ml-regform-field-timezone',
              }}
            >
              {miscStore.timeZones.map((v) =>
                <option key={v.id} value={v.id} title={v.name}>
                  {v.shortName}
                </option>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label='Адрес'
              margin='normal'
              value={fieldValues.fullAddress}
              helperText={validationErrors.fullAddress !== undefined ? validationErrors.fullAddress : ''}
              onChange={handleChange('fullAddress')}
            />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            required={false}
            label='Новый пароль'
            type='password'
            margin='normal'
            value={fieldValues.password}
            error={validationErrors.password !== undefined}
            helperText={validationErrors.password !== undefined ? validationErrors.password : ''}
            onChange={handleChange('password')}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            required={false}
            label='Повтор пароля'
            type='password'
            margin='normal'
            value={fieldValues.repeatPassword}
            error={validationErrors.repeatPassword !== undefined}
            helperText={validationErrors.repeatPassword !== undefined ? validationErrors.repeatPassword : ''}
            onChange={handleChange('repeatPassword')}
          />
        </Grid>

        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Button variant='contained' color='primary' disabled={!formChanged} onClick={handleSaveClick}>Сохранить</Button>
        </Grid>
      </Grid>
    </Paper>
  )
})

export default RegDataForm
