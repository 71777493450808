import './index.css'

import * as serviceWorker from './serviceWorker'

import App from './App'
import { Provider } from 'mobx-react'
import React from 'react'
import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'
import stores from './stores'

// For easier debugging
window.__APP_STATE__ = stores;

ReactDOM.render((
    <Provider {...stores}>
      <SnackbarProvider
        autoHideDuration={2000}
        preventDuplicate={true}
        maxSnack={4}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  ),
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
