import {
  Link as RouterLink,
  useParams
} from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import { recoverPassword } from './WebApi'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  grid: {
    alignItems: 'top'
  }
}))

const ForgotPassword = observer(() => {
  const classes = useStyles()
  const {confirmCode} = useParams();
  const [fieldValues, setFieldValues] = React.useState({
    newPassword: '',
    repeatPassword: ''
  })

  const [resultMessage, setResultMessage] = React.useState('')

  const {enqueueSnackbar} = useSnackbar()

  const handleChange = (fieldName, checkbox) => event => {
    setFieldValues({
        ...fieldValues,
        [fieldName]: checkbox === true ? event.target.checked : event.target.value,
    })
  }

  const [validationErrors, setValidationErrors] = React.useState({})
  const [requesting, setRequesting] = React.useState(false)

  const validate = () => {
    const errors = {}
    const requiredFields = ['newPassword']

    requiredFields.forEach(f => {
      if (fieldValues[f] === '' || fieldValues[f] === null) {
        errors[f] = ''
      }
    })

    if (fieldValues.repeatPassword !== fieldValues.newPassword) {
      errors['newPassword'] = ''
      errors['repeatPassword'] = 'Пароли должны совпадать'
    }
    setValidationErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleOkClick = event => {
    if (!validate()) {
      return
    }

    setRequesting(true)
    recoverPassword(confirmCode, fieldValues.newPassword)
      .finally(() => setRequesting(false))
      .then(resp => resp.json())
      .then(data => {
        if (!data.success) {
          if (data.validationErrors) {
            setValidationErrors(data.validationErrors)
          } else {
            enqueueSnackbar(data.message, {variant: 'error'})
          }
        } else {
          setResultMessage(data.message)
        }
      })
  }

  if (resultMessage) {
    return (
      <div className='page-container'>
        <Typography variant='h5' gutterBottom>
          {resultMessage}
        </Typography>

        <Typography variant='body1'>
          <RouterLink to='/'>Приступить к работе</RouterLink>
        </Typography>
      </div>
    )
  }

  return (
    <div className='page-container'>

      <Typography variant='h4' gutterBottom>
        Восстановление доступа
      </Typography>

      <Typography variant='body1' paragraph>
        Задайте новый пароль для Вашей учетной записи.
      </Typography>
      <form noValidate>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={6}>
            <TextField
                fullWidth
                required
                type='password'
                label='Новый пароль'
                margin='normal'
                variant='outlined'
                value={fieldValues.newPassword}
                error={validationErrors.newPassword !== undefined}
                helperText={validationErrors.newPassword !== undefined ? validationErrors.newPassword : ''}
                onChange={handleChange('newPassword')}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
                fullWidth
                required
                type='password'
                label='Повтор пароля'
                margin='normal'
                variant='outlined'
                value={fieldValues.repeatPassword}
                error={validationErrors.repeatPassword !== undefined}
                helperText={validationErrors.repeatPassword !== undefined ? validationErrors.repeatPassword : ''}
                onChange={handleChange('repeatPassword')}
            />
          </Grid>

          <Grid item xs style={{textAlign: 'center'}}>
            <Button variant='contained' color='primary' type='submit' disabled={requesting} onClick={handleOkClick}>Готово</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
})

export default ForgotPassword
