import { createPaidLiccert, createUnpaidLiccert, requestLiccertDefaults, requestLiccertPrice } from '../WebApi'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { debounce } from 'lodash'
import { formFieldChangeHandler } from '../utils'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useStores } from '../hooks'

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(10, 10),
      width: '700px',
      margin: '0 auto'
    },
    grid: {
      marginTop: '1em'
    },
    error: {
      color: theme.palette.error.dark
    }
}))

const PAID_LICCERT_TYPE_ID = 1
const UNPAID_LICCERT_TYPE_ID = 2

function nullsToEmptyStrings(obj) {
  const res = {}
  for (var prop in obj) {
    res[prop] = obj[prop] === null ? '' : obj[prop]
  }
  return res
}

function LiccertForm() {
  const classes = useStyles()
  const history = useHistory()
  const {miscStore} = useStores()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const [fieldValues, setFieldValues] = React.useState({
    type: PAID_LICCERT_TYPE_ID,
    beginDate: '',
    endDate: '',
    licenseCount: '',
    discountCode: '',
    discount: '',
    price: ''
  })

  const [error, setError] = React.useState('')

  const handleFieldChange = fieldName => event => {
    const values = {
      ...fieldValues,
      [fieldName]: event.target.value
    }

    requestLiccertPrice({
      licenseCount: values.licenseCount,
      beginDate: values.beginDate,
      endDate: values.endDate,
    }).then(resp => {
      setValidationErrors({})

      if (resp.success) {
        setError('')
        values.price = resp.data.price
      } else {
        if (resp.validationErrors) {
          setValidationErrors(resp.validationErrors)
        }

        setError(resp.message)

        values.price = ''
      }
    }).finally(() => {
      setFieldValues(values)
    })
  }
  // formFieldChangeHandler(fieldValues, setFieldValues)

  const [validationErrors, setValidationErrors] = React.useState({})

  const validate = () => {
    const errors = {}
    const requiredFields = ['licenseCount', 'beginDate', 'endDate']

    requiredFields.forEach(f => {
      if (fieldValues[f] === "") {
        errors[f] = ""
      }
    })

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      return false
    }

    setValidationErrors({})
    return true
  }

  React.useEffect(() => {
    requestLiccertDefaults(PAID_LICCERT_TYPE_ID)
      .then(resp => {
        if (resp.success) {
          setFieldValues({
            ...fieldValues,
            ...nullsToEmptyStrings(resp.data),
          })
        }
      })
  }, [])

  const handleLiccertTypeChange = event => {
    const type = parseInt(event.target.value)

    requestLiccertDefaults(type)
      .then(resp => {
        if (resp.success) {
          setValidationErrors({})
          setError('')
          setFieldValues({
            ...fieldValues,
            ...nullsToEmptyStrings(resp.data),
            type
          })
        }
      })
  }

  const handleCreateClick = event => {
    let createPromise

    if (fieldValues.type == PAID_LICCERT_TYPE_ID) {
      const {beginDate, endDate, licenseCount} = fieldValues
      createPromise = createPaidLiccert({
        beginDate,
        endDate,
        licenseCount
      })
    } else {
      createPromise = createUnpaidLiccert()
    }

    createPromise.then(resp => {
      if (!resp.success) {
        enqueueSnackbar(resp.message, {variant: 'error'})
      } else {
        enqueueSnackbar('Сертификат успешно создан', {variant: 'success'})
        history.replace('/liccerts')
      }
    })
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Новый лицензионный сертификат
      </Typography>

      <Grid container spacing={4} className={classes.grid}>
        <Grid item xs={8}>
          <FormControl fullWidth error={validationErrors.countryId !== undefined}>
            <InputLabel required htmlFor="ca-licform-type">Тип сертификата</InputLabel>
            <Select
              native
              value={fieldValues.type}
              onChange={handleLiccertTypeChange}
              inputProps={{
                name: 'type',
                id: 'ca-licform-type',
              }}
            >
              {miscStore.licCertTypes.map(v => <option key={v.id} value={v.id}>{v.name}</option>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>&nbsp;</Grid>

        {/*fieldValues.type === PAID_LICCERT_TYPE_ID &&
          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              Онлайн оплата должна быть осуществлена в течение 24 часов, банковский перевод в течение 5 рабочих дней.
            </Typography>
          </Grid>
        */}

        <Grid item xs={4}>
            <TextField
              fullWidth
              required={fieldValues.type !== UNPAID_LICCERT_TYPE_ID}
              disabled={fieldValues.type === UNPAID_LICCERT_TYPE_ID}
              type="number"
              label="Количество лицензий"
              value={fieldValues.licenseCount}
              onChange={handleFieldChange('licenseCount')}
              error={validationErrors.licenseCount !== undefined}
              helperText={validationErrors.licenseCount !== undefined ? validationErrors.licenseCount : ''}
            />
        </Grid>
        <Grid item xs={4}>
            <TextField
              fullWidth
              required={fieldValues.type !== UNPAID_LICCERT_TYPE_ID}
              disabled={fieldValues.type === UNPAID_LICCERT_TYPE_ID}
              type="date"
              label="Дата начала"
              value={fieldValues.beginDate}
              onChange={handleFieldChange('beginDate')}
              error={validationErrors.beginDate !== undefined}
              helperText={validationErrors.beginDate !== undefined ? validationErrors.beginDate : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
        </Grid>
        <Grid item xs={4}>
            <TextField
              fullWidth
              required={fieldValues.type !== UNPAID_LICCERT_TYPE_ID}
              disabled={fieldValues.type === UNPAID_LICCERT_TYPE_ID}
              type="date"
              label="Дата окончания"
              value={fieldValues.endDate}
              onChange={handleFieldChange('endDate')}
              error={validationErrors.endDate !== undefined}
              helperText={validationErrors.endDate !== undefined ? validationErrors.endDate : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
        </Grid>

        {fieldValues.type === PAID_LICCERT_TYPE_ID &&
          <React.Fragment>
            {/*
            <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Код скидки"
                  value={fieldValues.discountCode}
                  onChange={handleFieldChange('discountCode')}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Скидка"
                  value={fieldValues.discount}
                  onChange={handleFieldChange('discount')}
                />
            </Grid>
            <Grid item xs={4}>&nbsp;</Grid>
            */}

            {error &&
              <Grid item xs={12}>
                <FormHelperText error={true}>{error}</FormHelperText>
              </Grid>
            }

            <Grid item xs={12} style={{textAlign: 'right', fontWeight: 'bold'}}>
              {fieldValues.price !== '' &&
                <React.Fragment>
                  Итого: {parseFloat(fieldValues.price).toLocaleString(undefined, {minimumFractionDigits: 2})} RUB
                </React.Fragment>
              }
              {/*
              <FormControl fullWidth>
                <InputLabel required ref={currencyLabelRef} htmlFor="ca-licform-currency">Валюта</InputLabel>
                <Select
                  native
                  value={fieldValues.currency}
                  onChange={handleFieldChange('currency')}
                  labelWidth={labelWidths.currency}
                  inputProps={{
                    name: 'type',
                    id: 'ca-licform-currency',
                  }}
                >
                  <option value="" />
                  {miscStore.currencies.map(v => <option key={v.id} value={v.id}>{v.mnemoCode}</option>)}
                </Select>
              </FormControl>
              */}
            </Grid>
            {/*<Grid item xs={4}>&nbsp;</Grid>*/}
          </React.Fragment>
        }
        <Grid item xs={12} style={{textAlign: 'center'}}>
          {(fieldValues.type === UNPAID_LICCERT_TYPE_ID || fieldValues.price) &&
            <Button variant='contained' color='primary' onClick={handleCreateClick}>Создать</Button>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default LiccertForm
