const commonFetchParams = {
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json'
    }
}

const apiBase = '/ca'

export function loadMiscData() {
  return fetch(`${apiBase}/misc/all`, {
      ...commonFetchParams,
      method: 'GET'
  })
}

export function loadAccount() {
  return fetch(`${apiBase}/account`, {
      ...commonFetchParams,
      method: 'GET',
  })
  .then(resp => resp.json())
}

export function createAccount(data) {
  return fetch(`${apiBase}/account`, {
      ...commonFetchParams,
      method: 'PUT',
      body: JSON.stringify(data)
  })
  .then(resp => resp.json())
}

export function editAccount(data) {
  return fetch(`${apiBase}/account`, {
      ...commonFetchParams,
      method: 'POST',
      body: JSON.stringify(data)
  })
}

export function loadLiccerts() {
  return fetch(`${apiBase}/liccert/list`, {
      ...commonFetchParams,
      method: 'GET'
  })
  .then(resp => resp.json())
}

export function requestLiccertDefaults(typeId) {
  return fetch(`${apiBase}/liccert/request/defaults/${typeId}`, {
      ...commonFetchParams,
      method: 'GET'
  })
  .then(resp => resp.json())
}

export function requestLiccertPrice(data) {
  return fetch(`${apiBase}/liccert/request/price`, {
      ...commonFetchParams,
      method: 'POST',
      body: JSON.stringify(data)
  })
  .then(resp => resp.json())
}

export function createUnpaidLiccert() {
  return fetch(`${apiBase}/liccert/unpaid`, {
      ...commonFetchParams,
      method: 'POST'
  })
  .then(resp => resp.json())
}

export function createPaidLiccert(data) {
  return fetch(`${apiBase}/liccert/paid`, {
      ...commonFetchParams,
      method: 'POST',
      body: JSON.stringify(data)
  })
  .then(resp => resp.json())
}

export function loadUser() {
  return fetch(`${apiBase}/auth/user`, {
      ...commonFetchParams,
      method: 'GET'
  })
  // .then(resp => resp.json())
}

export function login(username, password) {
  return fetch(`${apiBase}/auth/login`, {
      ...commonFetchParams,
      method: 'POST',
      body: JSON.stringify({username, password})
  })
}

export function logout() {
  return fetch(`${apiBase}/auth/logout`, {
      ...commonFetchParams,
      method: 'GET'
  })
}

export function confirmEmail(code) {
  return fetch(`${apiBase}/account/email/confirm/${code}`, {
      ...commonFetchParams,
      method: 'GET'
  })
}

export function passwordRecoverRequest(email) {
  return fetch(`${apiBase}/account/password/recover/byemail/${email}`, {
      ...commonFetchParams,
      method: 'POST'
  })
}

export function recoverPassword(code, newPassword) {
  return fetch(`${apiBase}/account/password/recover`, {
      ...commonFetchParams,
      method: 'POST',
      body: JSON.stringify({code, newPassword})
  })
}

export function checkRecaptcha(token) {
  return fetch(`${apiBase}/recaptcha/request`, {
      ...commonFetchParams,
      method: 'POST',
      body: JSON.stringify({token})
  })
}
