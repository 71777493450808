import * as WebApi from '../WebApi'

import { action, decorate, observable, runInAction } from 'mobx'

import { loadReCaptcha } from 'react-recaptcha-v3'

class MiscStore {
  loaded = false
  countries = []
  timeZones = []
  licCertTypes = []
  currencies = []
  recaptchaSiteKey = ''

  load() {
    WebApi.loadMiscData()
      .then(resp => resp.json())
      .then(data => {
        runInAction(() => {
          this.countries = data.countries
          this.timeZones = data.timeZones
          this.licCertTypes = data.licCertTypes
          this.currencies = data.currencies
          this.recaptchaSiteKey = data.recaptchaSiteKey
          this.loaded = true
        })

        loadReCaptcha(this.recaptchaSiteKey)
      })
  }
}

decorate(MiscStore, {
    loaded: observable,
    countries: observable,
    timeZones: observable,
    licCertTypes: observable,
    currencies: observable,
    recaptchaSiteKey: observable,
    load: action
})

export default new MiscStore()
