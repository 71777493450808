import { Link, Route, Switch } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Liccerts from './liccert/Liccerts'
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined'
import PropTypes from 'prop-types'
import React from 'react'
import RegDataForm from './regdata/RegDataForm'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useStores } from './hooks'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    flexDirection: 'row'
  },
  tabs: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: 'unset'
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  // value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { userStore } = useStores()

  const handleLogout = () => {
      userStore.logout()
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Tabs className={classes.tabs} value={value} onChange={handleChange}>
          <Tab label="Лицензионные сертификаты" {...a11yProps(0)} component={Link} to='/liccerts' />
          {/*
          <Tab label="Сообщения" {...a11yProps(1)} />
          <Tab label="Журнал событий" {...a11yProps(2)} />
          */}
          <Tab label="Регистрационные данные" {...a11yProps(3)} component={Link} to='/regdata' />
        </Tabs>
        <Toolbar className={classes.toolbar}>
          <Typography variant="subtitle2" color="inherit">
            {userStore.username}
          </Typography>
          <Tooltip title="Выход">
            <IconButton edge="end" color="inherit" onClick={handleLogout}>
              <MeetingRoomOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Switch>
        <Route path="/liccerts">
          <TabPanel index={0}>
            <Liccerts />
          </TabPanel>
        </Route>

        <Route path="/regdata">
          <TabPanel index={3}>
            <RegDataForm />
          </TabPanel>
        </Route>
      </Switch>
    </div>
  )
}

export default SimpleTabs
