import * as WebApi from './WebApi'

import {
  Link as RouterLink,
  useParams
} from 'react-router-dom'

import React from 'react'
import Typography from '@material-ui/core/Typography'

function EmailConfirm() {
  const {confirmCode} = useParams();
  const [confirmResult, setConfirmResult] = React.useState({})

  React.useEffect(() => {
    WebApi.confirmEmail(confirmCode)
      .then(resp => resp.json())
      .then(data => {
        setConfirmResult(data)
      })
  }, [])

  return (
    <div className="page-container">
      <Typography variant="h5" gutterBottom>
        {confirmResult.message}
      </Typography>

      {confirmResult.success &&
        <Typography variant="body1">
          <RouterLink to="/">Приступить к работе</RouterLink>
        </Typography>
      }
    </div>
  )
}

export default EmailConfirm
