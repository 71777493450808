import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import React from 'react'
import { Link as RouterLink } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import { useStores } from './hooks'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  // '@global': {
  //   body: {
  //     backgroundColor: theme.palette.common.white,
  //   },
  // },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    textAlign: 'center'
  }
}))

const RegLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} to="/reg" {...props} />);
const ForgotPasswdLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} to="/forgot-passwd" {...props} />);

const SignIn = observer(() => {
  const classes = useStyles()

  const [fieldValues, setFieldValues] = React.useState({
    username: '',
    password: '',
  })

  // const [authError, setAuthError] = React.useState(false)

  const handleFieldChange = (fieldName, checkbox) => event => {
    setFieldValues({
        ...fieldValues,
        [fieldName]: checkbox === true ? event.target.checked : event.target.value,
    })
  }

  const [validationErrors, setValidationErrors] = React.useState({})

  const validate = () => {
    const errors = {}
    const requiredFields = ['username', 'password']

    requiredFields.forEach(f => {
      if (fieldValues[f] === "" || fieldValues[f] === null) {
        errors[f] = ""
      }
    })

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      return false
    }

    setValidationErrors({})
    return true
  }

  const { userStore } = useStores()

  const handleLogin = (event) => {
    event.preventDefault()

    if (validate()) {
      userStore.login(fieldValues.username, fieldValues.password)
      // .then(success => {
        // setAuthError(!success)
      // })
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Вход
        </Typography>
        <form className={classes.form} noValidate>
          {userStore.authError &&
            <FormHelperText className={classes.error} error={true}>Неверные имя пользователя или пароль</FormHelperText>
          }
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Адрес email"
            name="username"
            autoComplete="email"
            autoFocus
            value={fieldValues.username}
            onChange={handleFieldChange('username')}
            error={validationErrors.username !== undefined}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
            value={fieldValues.password}
            onChange={handleFieldChange('password')}
            error={validationErrors.password !== undefined}
          />
        {/*
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Запомнить меня"
          />
        */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
            onClick={handleLogin}
          >
            Войти
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={ForgotPasswdLink} variant="body2">
                Забыли пароль?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RegLink} variant="body2">
                Зарегистрироваться
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        {/*
          <Copyright />
        */}
      </Box>
    </Container>
  )
})

export default SignIn
