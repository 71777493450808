import './App.css'

import {
  Redirect,
  Route,
  HashRouter as Router,
  Switch
} from 'react-router-dom'

import EmailConfirm from './EmailConfirm'
import ForgotPassword from './ForgotPassword'
import PrivateOffice from './PrivateOffice'
import Progress from '@material-ui/core/LinearProgress'
import React from 'react'
import RecoverPassword from './RecoverPassword'
import RegistrationForm from './RegistrationForm'
import SignIn from './SignIn'
import { observer } from 'mobx-react'
// import { useSnackbar } from 'notistack'
import { useStores } from './hooks'

// import { useStores } from './hooks'

// fetch('/ca').then(res) {
//
// }

// class App extends React.Component {
//   handleClick = () => {
//     import('./RegistrationForm')
//       .then(({ RegistrationForm }) => {
//         // Use moduleA
//       })
//       .catch(err => {
//         // Handle failure
//       })
//   }
//   render() {
//     return (
//       <div>
//         <button onClick={this.handleClick}>Load</button>
//       </div>
//     )
//   }
// }
const App = observer(() => {
  const {userStore, miscStore} = useStores()

  React.useEffect(() => {
    userStore.loadUser()
    miscStore.load()
  }, [])

  if (userStore.loading) {
    return <Progress />
  }

  return (
    <Router basename='/'>
      {userStore.username === '' ? (
        <Switch>
          <Route path='/confirm-email/:confirmCode'>
            <EmailConfirm />
          </Route>
          <Route path='/reg'>
            <RegistrationForm />
          </Route>
          <Route path='/forgot-passwd'>
            <ForgotPassword />
          </Route>
          <Route path='/recover-passwd/:confirmCode'>
            <RecoverPassword />
          </Route>
          <Route path='/'>
            <SignIn />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route path='/confirm-email/:confirmCode'>
            <EmailConfirm />
          </Route>
          <Route path='/recover-passwd/:confirmCode'>
            <RecoverPassword />
          </Route>
          <Route path='/'>
              <React.Fragment>
                <Redirect to='/liccerts' />
                <PrivateOffice />
              </React.Fragment>
          </Route>
        </Switch>
      )}
    </Router>
  )
})

export default App
