import * as WebApi from '../WebApi'

import { action, decorate, observable, runInAction } from 'mobx'

class UserStore {
  loading = true
  username = ''
  accountId = null
  authError = false

  async loadUser() {
    this.loading = true

    const resp = await WebApi.loadUser()
    if (!resp.ok) {
      // runInAction(() => {
      //   this.username = ''
      //   this.accountId = null
      // })
    } else {
      const data = await resp.json()

      runInAction(() => {
        this.username = data.username
        this.accountId = data.accountId
        this.loading = false
      })
    }
  }

  async login(username, password) {
    this.loading = true
    this.authError = false

    const resp = await WebApi.login(username, password)

    if (!resp.ok) {
      runInAction(() => {
        this.loading = false
        this.authError = true
      })
      return false
    }

    const data = await resp.json()

    runInAction(() => {
      this.username = data.username
      this.accountId = data.accountId
      this.loading = false
      this.authError = false
    })

    return true
  }

  async logout() {
    this.loading = true

    const resp = await WebApi.logout()

    if (resp.ok) {
      runInAction(() => {
        this.username = ''
        this.accountId = null
        this.loading = false
      })      
    }
  }
}

decorate(UserStore, {
    loading: observable,
    username: observable,
    accountId: observable,
    authError: observable,
    loadUser: action,
    login: action,
    logout: action
})

export default new UserStore()
