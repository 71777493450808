import liccertStore from './liccertStore'
import miscStore from './miscStore'
import userStore from './userStore'

const stores = {
    liccertStore,
    userStore,
    miscStore
}

export default stores
