import * as WebApi from './WebApi'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { ReCaptcha } from 'react-recaptcha-v3'
import React from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useStores } from './hooks'

const RegistrationForm = observer(() => {
  const {enqueueSnackbar} = useSnackbar()
  const {miscStore} = useStores()

  const [fieldValues, setFieldValues] = React.useState({
    email: '',
    fullName: '',
    password: '',
    repeatPassword: '',
    countryId: '',
    fullAddress: '',
    timeZoneId: '',
    licenseAccepted: false
  })

  const [recaptchaToken, setRecaptchaToken] = React.useState('')
  const [recaptchaId, setRecaptchaId] = React.useState(0)
  const [accountCreatedMsg, setAccountCreatedMsg] = React.useState('')
  const [validationErrors, setValidationErrors] = React.useState({})
  const [labelWidths, setLabelWidths] = React.useState({
    country: 0,
    timeZone: 0
  })
  const [accountCreating, setAccountCreating] = React.useState(false)

  const countryLabelRef = React.useRef(null)
  const timeZoneLabelRef = React.useRef(null)

  React.useEffect(() => {
    setLabelWidths({
        country: countryLabelRef.current.offsetWidth,
        timeZone: timeZoneLabelRef.current.offsetWidth
    })
  }, [])

  React.useEffect(() => {
    const id = setInterval(() => {
      setRecaptchaId(recaptchaId + 1)
    }, 30000)

    return () => {
      clearInterval(id)
    }
  }, [recaptchaId])

  const handleChange = (fieldName, checkbox) => event => {
    setFieldValues({
        ...fieldValues,
        [fieldName]: checkbox === true ? event.target.checked : event.target.value,
    })
  }

  const validate = () => {
    const errors = {}
    const requiredFields = ['email', 'fullName', 'countryId', 'timeZoneId', 'fullAddress', 'password', 'repeatPassword']

    requiredFields.forEach(f => {
      if (fieldValues[f] === '' || fieldValues[f] === null) {
        errors[f] = ''
      }
    })

    if (fieldValues.repeatPassword !== fieldValues.password) {
      errors['password'] = true
      errors['repeatPassword'] = 'Пароли должны совпадать'
    }

    if (!fieldValues.licenseAccepted) {
      errors['licenseAccepted'] = true
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      return false
    }

    setValidationErrors({})
    return true
  }

  const handleCreateClick = event => {
    if (!validate()) {
      return
    }

    const {licenseAccepted, repeatPassword, ...dataToSend} = fieldValues
    setAccountCreating(true)

    WebApi.createAccount({...dataToSend, recaptchaToken})
      .finally(() => setAccountCreating(false))
      .then(resp => {
        if (!resp.success) {
          if (resp.validationErrors) {
            setValidationErrors(resp.validationErrors)
          } else {
            enqueueSnackbar(resp.message, {variant: 'error'})
          }
        } else {
          setAccountCreatedMsg(resp.message)
        }
      });
  }

  const timeZoneTitle = () => {
    var tz = miscStore.timeZones.find(v => v.id == fieldValues.timeZoneId)
    return tz === undefined ? '' : tz.name
  }

  if (accountCreatedMsg) {
    return (
      <Container maxWidth="md">
        <Typography variant="h5" gutterBottom>
          {accountCreatedMsg}
        </Typography>
      </Container>
    )
  }

  return (
    <Container maxWidth="md" style={{marginTop: '1em'}}>
      <ReCaptcha
        key={recaptchaId}
        sitekey={miscStore.recaptchaSiteKey}
        action='regform'
        verifyCallback={(resp) => setRecaptchaToken(resp)}
      />

      <Typography variant="h4" gutterBottom>
        Создание учетной записи
      </Typography>

      <Typography variant="body1" paragraph>
        Прежде чем продолжить создание учетной записи, внимательно ознакомьтесь
        с <a href="https://mightylinks.net/licenzionnoe-soglashenie" target="_blank">лицензионным соглашением</a> и <a href="https://mightylinks.net/politika-ispolzovaniya-registracionnyh-dannyh" target="_blank">политикой использования регистрационных данных</a>.
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
              fullWidth
              required
              label='E-mail'
              variant='outlined'
              value={fieldValues.email}
              error={validationErrors.email !== undefined}
              helperText={validationErrors.email !== undefined ? validationErrors.email : ''}
              onChange={handleChange('email')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
              fullWidth
              required
              label='Имя / Название'
              variant='outlined'
              value={fieldValues.fullName}
              error={validationErrors.fullName !== undefined}
              helperText={validationErrors.fullName !== undefined ? validationErrors.fullName : ''}
              onChange={handleChange('fullName')}
            />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant='outlined' error={validationErrors.countryId !== undefined}>
            <InputLabel required ref={countryLabelRef} htmlFor='ml-regform-field-country'>Страна</InputLabel>
            <Select
              native
              value={fieldValues.countryId}
              onChange={handleChange('countryId')}
              labelWidth={labelWidths.country}
              inputProps={{
                name: 'country',
                id: 'ml-regform-field-country',
              }}
            >
              <option value=''></option>
              {miscStore.countries.map((v) => <option key={v.id} value={v.id}>{v.name}</option>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant='outlined' error={validationErrors.timeZoneId !== undefined}>
            <InputLabel required ref={timeZoneLabelRef} htmlFor='ml-regform-field-timezone'>Временная зона</InputLabel>
            <Select
              native
              value={fieldValues.timeZoneId}
              title={timeZoneTitle()}
              onChange={handleChange('timeZoneId')}
              labelWidth={labelWidths.timeZone}
              inputProps={{
                name: 'timezone',
                id: 'ml-regform-field-timezone',
              }}
            >
              <option value=''></option>
              {miscStore.timeZones.map((v) => <option key={v.id} value={v.id} title={v.name}>{v.shortName}</option>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label='Адрес'
              variant='outlined'
              value={fieldValues.fullAddress}
              error={validationErrors.fullAddress !== undefined}
              helperText={validationErrors.fullAddress !== undefined ? validationErrors.fullAddress : ''}
              onChange={handleChange('fullAddress')}
            />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label='Пароль'
            type='password'
            variant='outlined'
            value={fieldValues.password}
            error={validationErrors.password !== undefined}
            helperText={validationErrors.password !== undefined ? validationErrors.password : ''}
            onChange={handleChange('password')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label='Повтор пароля'
            type='password'
            variant='outlined'
            value={fieldValues.repeatPassword}
            error={validationErrors.repeatPassword !== undefined}
            helperText={validationErrors.repeatPassword !== undefined ? validationErrors.repeatPassword : ''}
            onChange={handleChange('repeatPassword')}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl required error={validationErrors.licenseAccepted !== undefined} component='fieldset'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fieldValues.licenseAccepted}
                  onChange={handleChange('licenseAccepted', true)}
                  value='licenseAccepted'
                  color='primary'
                />
              }
              label='Выражаю согласие с условиями лицензионного соглашения и подтверждаю ознакомление с политикой использования регистрационных данных.'
            />
            {validationErrors.licenseAccepted !== undefined &&
              <FormHelperText>Согласие необходимо</FormHelperText>
            }
          </FormControl>
        </Grid>

        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Button variant='contained' color='primary' disabled={accountCreating} onClick={handleCreateClick}>Создать</Button>
        </Grid>
      </Grid>
    </Container>
  )
})

export default RegistrationForm
